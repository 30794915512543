

.chkBoxAgree
{
  margin-right: 5px;
}
.spinBlue{
background-color: #232F3E;
border-color: #232F3E;
}
.spinYellow{
  background-color: #ff9900;
  border-color: #ff9900;
  
}

.cardMessageBody{
  font-family: Arial;
  color: #3B3B3B;
  font-size: 14px;
}

.logo-customizable {
  max-width: 60%;
  max-height: 60%;
}

.form-control{
  height: 34px;
  font-size: 14px;
  font-family: Arial;

}

.form-control:focus {
  border-color: #232F3E;
  outline: 0 !important;
}
.form-row{
  margin-bottom: 3px;
}
label{
margin-bottom: 3px;
margin-left:1px;
}

p { word-break: normal }

.header {
  background: #232F3E;
  height: 60px;
  padding: 0 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
.header .logo-img {
  max-width: 170px;
  width: 100%;
  margin-bottom: -3px; }
.header .logo-text {
  font-size: 30px;
  color: #D8D8D8;
  margin-left: 14px; }


.amzn-btn {
  
  font-size: 14px;
    font-weight: bold;
    height: 40px;
    width: 100%;
    color: #fff;
    background-color: #232F3E!important;
    border: #232F3E !important;
    margin: 5px 0px 5px 0px;

}

.contentheader{
    color:#212529;
    font-size:1.2rem;
    font-family: Arial;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.amzn-btn:hover {
  background-color: #ff9900!important;
  border: #232F3E !important;
  border-style: outset !important;
  border-width: 1px !important;
}

.error-lbl{
  color: red;
}

center {
  text-align: -webkit-center;
}


:root {
  --themeColor: #ff9900;
  --hoverColor: #ff9900;; 
  --amplify-primary-color: #232F3E;
  --amplify-primary-tint: #ff9900; 
  --amplify-primary-shade: #ff9900;
  --amplify-font-family: Arial;
  --amplify-secondary-color:#3B3B3B;
  --amplify-tertiary-tint:var(--themeColor);
  
 
}

.button:hover { 
  background-color: #ff9900!important;
  border: #232F3E !important;
  border-style: outset !important;
  border-width: 1px !important;

}

.errorMessage-customizable {
  padding: 5px;
  font-size: 14px;
  width: 100%;
  background: #F5F5F5;
  border: 2px solid #D64958;
  color: #D64958;
}

.infoMessage-customizable {
  padding: 5px;
  font-size: 14px;
  width: 100%;
  background: #F5F5F5;
  border: 2px solid rgb(51, 122, 183);
  color:rgb(51, 122, 183);
}








